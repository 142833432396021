import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/assets/js/util'

Vue.use(VueRouter)

import pc from '@/router/pc';
import h5 from '@/router/h5';


const routes = [{
    	path: "/",
        component: {template: "<router-view></router-view>"},
    	redirect: "/index"
    },
    ...pc,
    ...h5,
    {
        path: '/notFound',
        name: 'notFound',
        component: () => import('@/views/notFound.vue')
    }
]

const router404=[
	{
		path: "*",
		redirect: "/notFound",
	}
]
const router = new VueRouter({
	mode: "history",
	base: "/", //加上这一行
	routes: [
        ...routes,
		...router404
	],
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		};
	},
});

router.beforeEach((to, from, next) => {
	if(to.name=='notFound'){
		next();
	}else{
        if(util.isMobile()==true && to.fullPath.indexOf('h5')==-1){
            next('/h5'+to.fullPath);
        }else if(util.isMobile()==false && to.fullPath.indexOf('h5')!=-1){
            next(to.fullPath.substring(3))
        }else{
            next();
        }
	}
});
export default router
