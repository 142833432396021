export default [
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('@/views/product.vue')
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs.vue')
    }
]