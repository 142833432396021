export default [
    {
        path: '/h5/aboutUs',
        name: 'h5AboutUs',
        component: () => import('@/views/h5/aboutUs.vue')
    },
    {
        path: '/h5/index',
        name: 'h5Index',
        component: () => import('@/views/h5/index.vue')
    },
    {
        path: '/h5/product',
        name: 'h5Product',
        component: () => import('@/views/h5/product.vue')
    }
]