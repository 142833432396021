import axios from '@/request/service'; // 导入http中创建的axios实例

const index = {    
	// getMenuList (params) {        
 //        return axios.post('/api/h5/get_menu_list', params);    
 //    }
	
    // 其他接口…………
}

export default index;