/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
const qs = require('qs');
// import { ElLoading,ElMessage } from 'element-plus'
// let load_tip = null;
import util from '@/assets/js/util'
/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */ 
// 创建axios实例
axios.defaults.withCredentials = true;
let instance = axios.create({ 
	timeout: 1000 * 12,
	headers: {
		'Cache-Control': 'no-cache',
		// 'X-Custom-Foo':'bar'
	}
});
/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */ 
let needLoadingRequestCount = 0;
instance.interceptors.request.use(
	config => {
		//转化成Form Data,使用qs模块对其进行处理
		if (config.method == "post") {
			// config.data = qs.stringify(config.data);
        }
        // if (needLoadingRequestCount == 0 && load_tip == null) {
            // load_tip = ElLoading.service({
            //     fullscreen: true
            // })
        // }
        needLoadingRequestCount++;
		config.headers.token = window.localStorage.getItem("tk");
		return config;
	},
	err => {
		return Promise.reject(err);
	});
// 响应拦截器
instance.interceptors.response.use(    
    // 请求成功
	response => {
        needLoadingRequestCount--;
			// if (needLoadingRequestCount === 0) {
			// 	load_tip.close();
			// 	load_tip = null;
			// }	
            // if(response.data.code === 401&&needLoadingRequestCount === 0){
		if(response.data.code === 401){
			window.localStorage.clear();
			// util.toLogin();
		}else{
			return Promise.resolve(response);
		}
	},   
// 
    // 请求失败
    error => {
        needLoadingRequestCount--;
			// if (needLoadingRequestCount === 0) {
			// 	load_tip.close();
			// 	load_tip = null;
			// }	
		if (error.response) {
			switch (error.response.status) {
				case 500:
					util.laytips("error", '网络错误，请稍后重试~', 1000);
					break;
			}
		}
		return Promise.reject(error.response) // 返回接口返回的错误信息
    });
export default instance;