import router from '@/router'
import { Message } from "element-ui";
export default {
    laytips(type, msg, duration) {
        //提示信息
        Message.closeAll();
        Message({
            showClose: true,
            message: msg,
            type: type,
            duration: duration ? duration : 0,
        });
    },
    trim(s) {
        //去空格
        return s.replace(/(^\s*)|(\s*$)/g, "");
    },
    isMobile(){
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)==null?false:true;
        return flag;
    }
}
