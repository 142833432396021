let https={};
function importAllRouter(r) {
	r.keys().forEach(keys => {
		let key_name=keys.substring(2,keys.length-3);
		https[key_name]=r(keys).default;
	});
}
/**
 *  require.context  是webpack里面的方法  用于获取文件   
 * 第一个参数是文件目录  
 * 第二个参数是是否查看子目录  
 * 第三个参数是什么类型的文件
* */

importAllRouter(require.context( './module', false,/.js/));
export default https;